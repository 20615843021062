import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import CustomEase from 'gsap/CustomEase'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import StarSVGComponent from '../StarSVGComponent/StarSVGComponent'
import "./Loader.css";

gsap.registerPlugin(useGSAP);

const Loader = ({setIsLoading}) => {
    const [currentValue, setCurrentValue] = useState(0)
    const [incrementValue, setIncrementValue] = useState(15)
    const [loadingText, setLoadingText] = useState("Analyzing your unique traits...")

    const maxValue = 100

    const increment = useCallback(async () => {
        if (currentValue + incrementValue > 100) {
            await new Promise((r) => setTimeout(r, 500))
            setIsLoading(false)
            // setCurrentValue(0)
            // setIncrementValue(15)
        } else {
            setCurrentValue((v) => v + incrementValue)
            setIncrementValue((prev) => {
                if (prev === 15) return 30
                if (prev === 30) return 31
                if (prev === 31) return 14
                if (prev === 14) return 10

                return 15
            })
        }
    }, [currentValue, incrementValue, setIsLoading])

    useEffect(() => {
        const r = setInterval(() => {
            increment()
        }, incrementValue * 150 / 2)

        return () => {
            clearInterval(r)
        }
    }, [increment])

    useEffect(() => {
        const timer1 = setTimeout(() => setLoadingText("Unlocking insights into your strengths, weaknesses, and career potential"), 2500)
        const timer2 = setTimeout(() => setLoadingText("Please wait, your future is loading!"), 6000)
        // const timer3 = setTimeout(() => setLoadingText("Load"), 4000)

        return () => {
            clearTimeout(timer1)
            clearTimeout(timer2)
            // clearTimeout(timer3)
        }
    }, [])

    useGSAP(() => {
        const tl = gsap.timeline({ repeat: -1})
        
        tl.to(".loading-star", {
            scale: 0.75,
            stagger: -0.2,
            duration: 2,
            ease: CustomEase.create("custom", "M0,0 C0.056,0.171 0.2,1 0.2,1 0.2,1 0.527,-0.081 0.614,0 0.806,0.179 0.818,1.001 1,1 "),
        });
        tl.to(".loading-star", {
            scale: 0,
            stagger: 0.2,
            duration: 2,
            ease: CustomEase.create("custom", "M0,0 C0.056,0.171 0.2,1 0.2,1 0.2,1 0.527,-0.081 0.614,0 0.806,0.179 0.818,1.001 1,1 "),
        });
    })
  return (
    <div>
        <div className="h-screen w-screen bg-white flex flex-col gap-20 justify-center items-center relative z-[100]">
            <div className=" relative">
                <div className="h-[150px] w-[150px] bg-gradient-to-bl from-black to-black rounded-full flex justify-center items-center blur-xl animate-customspin absolute top-[25%] left-[25%] opacity-0"></div>
                {/* <div className="h-[200px] w-[200px] bg-gradient-to-bl from-[#ffd600] to-[#ff0000] rounded-full flex justify-center items-center blur-xl animate-customspin opacity-30"></div> */}
                <div className="h-[200px] w-[200px] bg-gradient-to-bl from-[#2bbfe2] to-[#d35aa2] rounded-full flex justify-center items-center blur-xl animate-customspin opacity-50"></div>
                {/* <div className="h-[100px] shadow-2xl w-[100px] bg-gradient-to-bl from-[#00FFFF] border-4 border-black to-[#ff0000] rounded-full blur-lg absolute top-[25%] left-[25%] animate-customspin opacity-30"></div> */}
                <div className="h-[100px] shadow-2xl w-[100px] bg-gradient-to-bl from-[#2d85c7] border-4 border-black to-[#316ab3] rounded-full blur-xl absolute top-[25%] left-[25%] animate-customspin opacity-50"></div>

                <StarSVGComponent className="absolute top-[40%] left-[55%] -translate-x-1/2 -translate-y-1/2 loading-star scale-0 !opacity-100" />
                <StarSVGComponent className="absolute top-[62%] left-[55%] -translate-x-1/2 -translate-y-1/2 loading-star scale-0 !opacity-100" />
                <StarSVGComponent className="absolute top-[50%] left-[40%] -translate-x-1/2 -translate-y-1/2 loading-star scale-0 !opacity-200" />
            </div>

            <progress
                className="progressBar overflow-hidden w-[500px] md-small:w-[300px]"
                value={currentValue}
                max={maxValue}
            >
                {currentValue}%
            </progress>

            <div className="text-black text-xl md-small:text-base md-small:text-center max-w-[80%]">{loadingText}</div>
        </div>
    </div>
  )
}

export default Loader