import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Data1 } from "../../utils/detailResult";
import DetailResultPageTopSection from "./DetailResultPageTopSection";
import DetailResultBottomSection from "./DetailResultBottomSection";
import DetailResultTabSection from "./DetailResultTabSection";
import { useRecoilState, useRecoilValue } from "recoil";
import { resultSelector, testResult } from "../../utils/recoil";
import config from '../../config.json';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { languagesCode } from "../../utils/data";
import Loader from "../Loader/Loader";

const DetailResultPage = () => {
  const result = useRecoilValue(resultSelector);
  const tokenCookie = Cookies.get('token', { domain: config.cookieDomain });
  const isPaidCookie = Cookies.get('role', { domain: config.cookieDomain });
  const isTestTaken = Cookies.get('isTestAttended', { domain: config.cookieDomain });
  const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    if (tokenCookie) {
      if (isPaidCookie && (isPaidCookie === 'ROLE_UNPAID_USER')) navigate('/home');
      if (isTestTaken && (isTestTaken === 'FALSE')) navigate('/home');
    } else {
      window.location.href = config.domain + '/login';
    }
  }, [])

  console.log(result);
  return (
    <Layout>
      <React.Suspense fallback={<LoadingPage />}>
        {
          result && [result]?.map((item) => (
            <div className="px-[4rem] py-[3rem] md-max:px-[2rem] md-max:[2rem] sm-max:px-0 sm-max:py-0">
              <DetailResultPageTopSection item={item} />
              <DetailResultTabSection item={item} />
              <DetailResultBottomSection />


            </div>
          ))}
      </React.Suspense>
    </Layout>
  );
};

export default DetailResultPage;
