import React, { Suspense } from 'react'
import Header from '../Header/Header'
import { LoadingPage } from '../LoadingPage/LoadingPage'
// import Footer from '../Footer/Footer'

const Layout = ({ children }) => {
  return (
    <Suspense fallback={<LoadingPage />}>

      <div className='layoutmian'>

        <Header />
        {children}
        {/* <Footer/> */}
      </div>
    </Suspense>
  )
}

export default Layout