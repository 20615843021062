import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import { GoDotFill } from "react-icons/go";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TabSection = ({ item }) => {
  const getValues = (data1, data2) => {
    // const [largeValue,setLargeVAlue]=useState()
    let colorClass, justify, largerPercentage;
    largerPercentage = data1 > data2 ? data1 : data2;
    // setLargeVAlue(largerPercentage)
    if (largerPercentage === data1) {
      justify = "justify-start";
      colorClass = "bg-green-500";
    } else if (largerPercentage === data2) {
      justify = "justify-end";
      colorClass = "bg-green-500";
    }
    return { colorClass, justify, largerPercentage };
  };
  const [activeTab, setActiveTab] = useState(0);
  const totalTabs = 4;

  const transformedData = [
    [
      { key: "Introverted", value: item.prediction?.mbti.values.INTROVERT, main_title: "Energy", detail: 'Extraversion and Introversion represent two distinct ways of engaging with the world: one through outward exploration and interaction, and the other through inward reflection and introspection.' },
      { key: "Extroverted", value: item.prediction?.mbti.values.EXTROVERT, main_title: "Energy", detail: 'Extraversion and Introversion represent two distinct ways of engaging with the world: one through outward exploration and interaction, and the other through inward reflection and introspection.' },
    ],
    [
      { key: "Intuition", value: item.prediction?.mbti.values.INTUITION, main_title: "Mind", detail: "These two preferences represent different ways of perceiving and processing information, with Sensing focusing on the here and now, while Intuition looks to the future and explores broader patterns and possibilities." },
      { key: "Sensing", value: item.prediction?.mbti.values.SENSING, main_title: "Mind" },
    ],
    [
      { key: "Thinking", value: item.prediction?.mbti.values.THINKING, main_title: "Nature", detail: "Individuals who prefer Feeling prioritize personal values and empathy in decision-making, while those who prefer Thinking prioritize logic and rationality. Both traits contribute to diverse perspectives and approaches to problem-solving in different situations." },
      { key: "Feeling", value: item.prediction?.mbti.values.FEELING, main_title: "Nature" },
    ],
    [
      { key: "Prospecting", value: item.prediction?.mbti.values.PROSPECTING, main_title: "Tactics", detail: "These traits represent different ways in which individuals approach and interact with the external world, influencing their decision-making processes, planning styles, and overall lifestyle preferences." },
      { key: "Judging", value: item.prediction?.mbti.values.JUDGING, main_title: "Tactics" },
    ],
  ];
  return (
    <div>
      <Tab.Group onChange={(index) => setActiveTab(index)}>
        <div className="flex  rounded-2xl bg-white overflow-hidden sm-max:hidden">
          <Tab.List className="flex  flex-col w-2/5 bg-[#EDEDED]">
            <div className="bg-white w-full flex flex-col">
              {transformedData?.map((item, index) => (
                <Tab
                  key={index}
                  className={`py-[1.5rem] px-[1rem] outline-none ${index === activeTab
                    ? "flex bg-white rounded-bl-2xl rounded-tl-2xl "
                    : index === (activeTab - 1 + totalTabs) % totalTabs && index < totalTabs - 1
                      ? "bg-[#EDEDED] rounded-br-2xl "
                      : activeTab === totalTabs - 1 && index < totalTabs - 1
                        ? "bg-[#EDEDED] "
                        : index === (activeTab + 1) % totalTabs
                          ? "bg-[#EDEDED] rounded-tr-2xl "
                          : "bg-[#EDEDED]"
                    } `}
                >
                  <div className="w-full">
                    <div
                      className={`w-full flex ${getValues(item[0].value, item[1].value).justify
                        } bg-[#D9D9D9] rounded-full`}
                    >
                      <div
                        className={`rounded-full h-[10px] ${getValues(item[0].value, item[1].value).colorClass
                          }`}
                        style={{
                          width: `${getValues(item[0].value, item[1].value)
                            .largerPercentage
                            }`,
                        }}
                      ></div>
                    </div>

                    <div className="flex justify-between">
                      <div>
                        <p>{parseInt(item[0].value?.split('.')[0])}%</p>
                        {item[0].key}
                      </div>
                      <div>
                        <p className="text-right">{parseInt(item[1].value?.split('.')[0])}%</p>
                        <p> {item[1].key}</p>
                      </div>
                    </div>
                  </div>
                </Tab>
              ))}
            </div>
          </Tab.List>
          <Tab.Panels className="w-3/5">
            {transformedData?.map((item) => {
              let largerPercentage, largerPercentage_key, main_title;
              largerPercentage =
                item[0].value > item[1].value ? item[0].value : item[1].value;
              largerPercentage_key =
                item[0].value > item[1].value ? item[0].key : item[1].key;
              main_title =
                item[0].value > item[1].value ? item[0].main_title : item[1].main_title;
              return (
                <Tab.Panel>
                  <div className="flex flex-col justify-center items-center px-[2rem] py-9 gap-[2rem] md-max:gap-5">
                    <p className="text-[#3976f9] text-[3rem] font-semibold">
                      {main_title}
                    </p>
                    <div className="flex flex-col items-center">
                      <p className="text-[2rem] font-semibold">
                        {parseInt(largerPercentage?.split('.')[0])}%
                      </p>
                      <p className="text-2xl font-semibold">
                        {largerPercentage_key}
                      </p>
                    </div>
                    <div className="flex flex-col gap-3 justify-center items-center">
                      <p className="text-center w-9/12">
                        {transformedData[activeTab][0]?.detail}
                      </p>
                      {/* <p className="text-center">
                        Dictum vitae non in elementum elit egestas nisl lorem
                        facilisi.
                      </p> */}
                    </div>
                  </div>
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};

export default TabSection;







