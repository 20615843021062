import React from "react";
import { RiInstagramLine } from "react-icons/ri";
import mail_icon from "../../Assets/mail.png";
import twiter_icon from "../../Assets/twiter.png";
import result_character from "../../Assets/result_character.png";
import { useNavigate } from "react-router-dom";

const SideBar = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div className="">

      <div className="bg-[#EDEDED] px-4 py-11  flex flex-col gap-[6rem] rounded-2xl sm-max:px-2 sm-max:py-5 md-max:gap-[2rem] sm-max:gap-6">
        <div className="  flex flex-col items-center gap-[2rem] sm-max:items-start">
          <div className="gap-[2rem] flex flex-col items-center sm-max:flex-row ">
            <img
              src={item?.imageUrls?.split(',')[0] ? item.imageUrls.split(',')[0] : result_character}
              alt="result Character"
              className="object-cover w-[7.125rem] h-[7.125rem] rounded-full"
            />
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-3 sm-max:gap-0">
                <p className="text-xl  text-[#3976f9] font-semibold  text-center">
                  {item?.name}
                </p>
                {/* <p className="text-xl text-center sm-max:text-start">
                  {item.prediction?.mbti.entity.code}-
                  {item.prediction?.holland.entity.code}
                </p> */}
              </div>
              <div className="flex items-center gap-6 lg:hidden md-max:hidden sm-max:flex">
                <img src={mail_icon} alt="icon" className="w-[25px] h-[20px]" />
                <img src={twiter_icon} alt="icon" className="w-[20px] h-[20px]" />
                <RiInstagramLine color="#1C75BC" className="w-6 h-6" />
              </div>
            </div>

          </div>
          <p className="text-center sm-max:text-justify md-max:px-10">{item.simpleDesc}</p>
        </div>
        <div className="flex flex-col gap-[2.625rem] items-center md-max:gap-[1rem] sm-max:items-start">
          <div>
            <button onClick={() => navigate('/detail_result_page')} className="bg-[#1c75bc] px-[3rem] py-[1rem] rounded-lg text-white">
              View Full Result
            </button>
          </div>
          <div className="flex flex-col gap-4 items-center justify-center border-t-2 pt-6 md-max:border-none sm-max:hidden">
            <p>Share Your Results</p>
            <div className="flex items-center gap-4">
              <img src={mail_icon} alt="icon" className="w-[25px] h-[20px]" />
              <img src={twiter_icon} alt="icon" className="w-[20px] h-[20px]" />
              <RiInstagramLine color="#1C75BC" className="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
