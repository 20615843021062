import { atom, selector } from "recoil";
import config from '../config.json';
import Cookies from 'js-cookie';

export const selectedLang = atom({
  key: 'selectedLang',
  default: { name: 'English', code: 'en' },
});

export const testResult = atom({
  key: 'testResult',
  default: selector({
    key: 'testResultSelector',
    get: () => {
      const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
      let user = JSON.parse(localStorage.getItem('user'));
      let resultFromLocalStorage;
      if (user && usernameCookie in user) {
        resultFromLocalStorage = user[usernameCookie]?.result;
      }
      console.log(resultFromLocalStorage);
      if (resultFromLocalStorage) {
        return resultFromLocalStorage;
      }
      return {};
    }
  }),
});

export const isAuthorized = atom({
  key: 'isAuthorized',
  default: false,
})

export const isPaid = atom({
  key: 'isPaid',
  default: false
})

export const isReviewSubmitted = atom({
  key: 'isReviewSubmitted',
  default: false
})

export const isLoginLoader = atom({
  key: 'isLoginLoader',
  default: false
})

export const languageSelectionModal = atom({
  key: 'languageSelectionModal',
  default: false
})

export const courseAmount = atom({
  key: 'courseAmount',
  default: 499
})

export const redeemedAmount = atom({
  key: 'redeemedAmount',
  default: 499
})

export const couponCode = atom({
  key: 'couponCode',
  default: null
})

export const questionsArray = atom({
  key: 'questionsArray',
  default: selector({
    key: 'questionsArraySelector',
    get: () => {
      let questions = JSON.parse(localStorage.getItem('questions'));
      if (questions) return questions;
      return [];
    }
  })
})

export const resultSelector = selector({
  key: 'resultSelector',
  get: async ({ get }) => {
    const _selectedLang = get(selectedLang)?.code;
    let user = JSON.parse(localStorage.getItem('user'));
    const userEmail = Cookies.get('email', { domain: config.cookieDomain })
    if (user && userEmail in user) {
      if (_selectedLang in user[userEmail]) {
        let resultFromLocalStorage = user[userEmail][_selectedLang];
        if (resultFromLocalStorage) {
          return resultFromLocalStorage;
        }
      } else {
        try {
          const response = await fetch(config.domain + `/results?lang=${_selectedLang}`, {
            method: "GET",
            credentials: "include",
          })
          const res1 = await response.json();
          const userDetails = {
            [userEmail]: {
              ...user[userEmail],
              [res1.data?.lang]: res1.data
            }
          }
          localStorage.setItem('user', JSON.stringify(userDetails));
          return res1.data;
        } catch (error) {
          throw error; // This will propagate to React Suspense or useRecoilValueLoadable
        }
      }
    } else {
      try {
        const response = await fetch(config.domain + `/results?lang=${_selectedLang}`, {
          method: "GET",
          credentials: "include",
        })
        const res1 = await response.json();
        const userDetails = {
          [userEmail]: {
            [res1.data?.lang]: res1.data
          }
        }
        localStorage.setItem('user', JSON.stringify(userDetails));
        return res1.data;
      } catch (error) {
        throw error; // This will propagate to React Suspense or useRecoilValueLoadable
      }

    }
  }
})