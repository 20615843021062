import React, { useEffect, useState } from "react";
import Quizhome from "./Components/Quiezhome/Quizhome";
import Layout from "./Components/Routing/Routingpage";
import Routingpage from "./Components/Routing/Routingpage";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import "primereact/resources/themes/saga-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css"; // Core CSS
import { RecoilRoot } from "recoil";
import ChatBot from "./Components/ChatBot/ChatBot";
import { BrowserRouter, useLocation } from "react-router-dom";
import { LoadingPage } from "./Components/LoadingPage/LoadingPage";

const App = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <BrowserRouter>
      <RecoilRoot>
        <PrimeReactProvider>
          <React.Suspense fallback={<LoadingPage />}>
            <div onClick={() => setShowModal(false)}>
              <Routingpage />
            </div>
          </React.Suspense>
          <ChatBot setShowModal={setShowModal} showModal={showModal} />
        </PrimeReactProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
};

export default App;
