import React, { startTransition } from "react";
import './Header.css'
import logo from "../../Assets/my-aptitude-ai-uslot.png";
import { IoCloseOutline } from "react-icons/io5";
import { Dropdown } from 'primereact/dropdown';
import { useRecoilState } from "recoil";
import { selectedLang } from "../../utils/recoil";
import { MdLanguage } from "react-icons/md";
import Cookies from 'js-cookie';
import config from '../../config.json';
import { Link, useLocation } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";

const Header = () => {
  const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
  const profileImage = Cookies.get('picture', { domain: config.cookieDomain });
  const tokenCookie = Cookies.get('token', { domain: config.cookieDomain });
  const navigate = useNavigate();

  const [lang, setLang] = useRecoilState(selectedLang);
  const cities = [
    { name: 'English', code: 'en' },
    { name: 'Hindi', code: 'hi' },
    { name: 'Malayalam', code: 'mal' },
    { name: 'Arabic', code: 'ar' },
    { name: 'Tamil', code: 'ta' },
  ];
  const location = useLocation();
  const currentUrl = location.pathname;

  return (
    <div className="quizsection-main">
      <div className="quiz-section-header">
        <div className="quiz-section-logo">
          <Link to={"/"}>  <img src={logo} alt="logo" className="object-cover" /></Link>
        </div>
        <div className="header-buttons">
          {(currentUrl !== '/privacy_policy'
            && currentUrl !== '/terms_conditon'
            && currentUrl !== '/instruction'
            && currentUrl !== '/cancellation_refund_policy') &&
            <div className="flex items-center px-2 bg-white rounded-md">
              <MdLanguage size={40} color="#1c75bc" />
              <Dropdown value={lang} onChange={(e) => startTransition(() => {setLang(e.value)})} options={cities} optionLabel="name"
                placeholder="Select Language" className="px-6 py-3 text-[16px ] "
                style={{ fontSize: "16px", fontWeight: "500", color: "black", width: '150px' }} />
            </div>
          }
          {tokenCookie && <div onClick={() => tokenCookie && navigate('/my_profile')} className="quiz-headerprofile-buttons">
            <button>
              <p>{usernameCookie && usernameCookie.split('-').join(' ')}</p>
              {/* <p>{usernameCookie && usernameCookie.substring(0, 4).split('-').join(' ')} {usernameCookie && usernameCookie.length > 4 ? '...' : ''}</p> */}
              {profileImage ?
                <img src={profileImage} alt="profile-img" width='38px' height='38px' style={{ borderRadius: '50%' }} />
                :
                <FaUserCircle size={38} color="#1C75BC" />
              }
            </button>
          </div>
          }
          {currentUrl === '/my_profile' &&
            <div
              onClick={() => navigate('/logout')}
              className="quiz-headerprofile-buttons" style={{ gap: '5px' }}>
              <button>
                Logout
                <CiLogout size={38} color="#1C75BC" />
              </button>
            </div>
          }
          {/* <button className="close-btns">
            <IoCloseOutline style={{ fontSize: "30px" }} />
            close
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
