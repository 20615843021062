import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React from "react";

gsap.registerPlugin(useGSAP);
const StarSVGComponent = ({ className }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M17.527 1.10482C17.7701 0.0790331 19.2299 0.079031 19.473 1.10482L20.7119 6.331C22 11.7648 26.3942 15.9135 31.8931 16.8873L35.4398 17.5154C36.5406 17.7103 36.5406 19.2898 35.4398 19.4847L31.8931 20.1128C26.3942 21.0867 22 25.2353 20.7119 30.6691L19.473 35.8953C19.2299 36.9211 17.7701 36.9211 17.527 35.8953L16.2881 30.6691C15 25.2353 10.6058 21.0867 5.10693 20.1128L1.56021 19.4847C0.459385 19.2898 0.459384 17.7103 1.56021 17.5154L5.10693 16.8873C10.6058 15.9135 15 11.7648 16.2881 6.331L17.527 1.10482Z"
        fill="url(#paint0_linear_10_97)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10_97"
          x1="18.5"
          y1="-2.99994"
          x2="18.5"
          y2="40.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDFF3D" />
          <stop offset="0.22" stopColor="white" />
          <stop offset="1" stopColor="#FF9900" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default StarSVGComponent;
