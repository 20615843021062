import React, { useState } from "react";

const MobProgressBar = ({ item }) => {
  const getValues = (data1, data2) => {
    let colorClass, justify, largerPercentage, key, main_title;
    largerPercentage = data1.value > data2.value ? data1.value : data2.value;
    if (largerPercentage === data1.value) {
      justify = "justify-start";
      colorClass = "bg-green-500";
      key = data1.key
      main_title = data1.main_title
    } else if (largerPercentage === data2.value) {
      justify = "justify-end";
      colorClass = "bg-green-500";
      key = data2.key
      main_title = data2.main_title
    }
    return { colorClass, justify, largerPercentage, key, main_title };
  };
  const transformedData = [
    [
      { key: "Extroverted", value: item.prediction?.mbti.values.EXTROVERT, main_title: "Energy:" },
      { key: "Introverted", value: item.prediction?.mbti.values.INTROVERT, main_title: "Energy:" },
    ],
    [
      { key: "Intuition", value: item.prediction?.mbti.values.INTUITION, main_title: "Mind:" },
      { key: "Sensing", value: item.prediction?.mbti.values.SENSING, main_title: "Mind:" },
    ],
    [
      { key: "Thinking", value: item.prediction?.mbti.values.THINKING, main_title: "Nature:" },
      { key: "Feeling", value: item.prediction?.mbti.values.FEELING, main_title: "Nature:" },
    ],
    [
      { key: "Prospecting", value: item.prediction?.mbti.values.PROSPECTING, main_title: "Tactics:" },
      { key: "Judging", value: item.prediction?.mbti.values.JUDGING, main_title: "Tactics:" },
    ],
  ];
  return (
    <div className="bg-[#EDEDED] px-2 py-[2rem] lg:hidden md-max:hidden sm-max:block rounded-2xl mt-11">
      {transformedData?.map((item, index) => (
        <div key={index} className={`py-[1rem] px-[0rem]`}>
          <div className="w-full">
            <div className="flex items-center mb-3 "><p className="text-[#3976f9] text-xl font-semibold">{getValues(item[0], item[1]).main_title}&nbsp;</p><p className="text-xl font-semibold">{parseInt(getValues(item[0], item[1]).largerPercentage?.split('.')[0])}%&nbsp;</p><p className="text-xl font-semibold">{getValues(item[0], item[1]).key}</p></div>
            <div
              className={`w-full flex ${getValues(item[0], item[1]).justify
                } bg-[#D9D9D9] rounded-full`}
            >
              <div
                className={`rounded-full h-[10px] ${getValues(item[0], item[1]).colorClass
                  }`}
                style={{
                  width: `${getValues(item[0], item[1]).largerPercentage
                    }`,
                }}
              ></div>
            </div>

            <div className="flex justify-between mt-2">
              <div>
                <p className="text-xl">{parseInt(item[0].value?.split('.')[0])}%</p>
                <p className="text-xl" >{item[0].key}</p>
              </div>
              <div>
                <p className="text-right text-xl">{parseInt(item[1].value?.split('.')[0])}%</p>
                <p className="text-xl"> {item[1].key}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobProgressBar;
