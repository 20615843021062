import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import "./ResultPage.css";
import person2 from "../../Assets/Frame 583 (1).png";
import { FaArrowLeft } from "react-icons/fa";
// import { CareerPAth } from "../CareerPath/CareerPAth";
import Cookies from 'js-cookie';
import { resultSelector, selectedLang, testResult } from "../../utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { log } from "debug";
import config from '../../config.json';
import energyimage from '../../Assets/Frame 583 (1).png'
import feelingsimage from '../../Assets/Frame 583 (2).png'
import mindimage from '../../Assets/Frame 583 (2).png'
import judgingimage from '../../Assets/Frame 583 (4).png'
import { languagesCode } from "../../utils/data";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import Loader from "../Loader/Loader";

const ResultPage = () => {
  const _testResult = useRecoilValue(resultSelector);
  const [count, setCount] = useState(0);
  const [resultPage, setResultPage] = useState(false);
  const tokenCookie = Cookies.get('token', { domain: config.cookieDomain });
  const isPaidCookie = Cookies.get('role', { domain: config.cookieDomain });
  const isTestTaken = Cookies.get('isTestAttended', { domain: config.cookieDomain });
  const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
  const navigate = useNavigate();
  const location = useLocation();
  const [resultdata, setResultData] = useState([])
  const [loader, setLoader] = useState(true)


  // console.log(_testResult);
  useEffect(() => {
    // console.log(isPaidCookie, tokenCookie);
    if (tokenCookie) {
      if (isPaidCookie && (isPaidCookie === 'ROLE_UNPAID_USER')) navigate('/home');
      if (isTestTaken && (isTestTaken === 'FALSE')) navigate('/home');
    } else {
      window.location.href = config.domain + '/login';
    }
  }, [])

  useEffect(() => {
    const energy = [];
    const feelings = [];
    const judging = [];
    const mind = [];

    const energyKeys = ['EXTROVERT', 'INTROVERT'];
    const feelingsKeys = ['FEELING', 'THINKING'];
    const judgingKeys = ['PROSPECTING', 'JUDGING'];
    const mindKeys = ['SENSING', 'INTUITION'];

    const traitNames = {
      E: 'Extroverted',
      I: 'Introverted',
      N: 'Intuition',
      S: 'Sensing',
      F: 'Feeling',
      T: 'Thinking',
      P: 'Prospecting',
      J: 'Judging',
    }

    _testResult && _testResult?.prediction?.mbti?.values && Object.keys(_testResult?.prediction?.mbti?.values).map(key => {
      if (energyKeys.includes(key)) {
        energy.push({
          name: key,
          value: parseInt(_testResult?.prediction?.mbti?.values[key].split('.')[0])
        });
      }
      if (feelingsKeys.includes(key)) {
        feelings.push({
          name: key,
          value: parseInt(_testResult?.prediction?.mbti?.values[key].split('.')[0])
        });
      }
      if (mindKeys.includes(key)) {
        mind.push({
          name: key,
          value: parseInt(_testResult?.prediction?.mbti?.values[key].split('.')[0])
        });
      }
      if (judgingKeys.includes(key)) {
        judging.push({
          name: key,
          value: parseInt(_testResult?.prediction?.mbti?.values[key].split('.')[0])
        });
      }
    })
    // console.log(energy);
    const resultImages = _testResult?.imageUrls?.split(',') ? _testResult?.imageUrls?.split(',') : [];
    setResultData([{
      image: resultImages[0] ? resultImages[0] : energyimage,
      description: _testResult.simpleDesc
    }
      ,
    {
      image: resultImages[0] ? resultImages[0] : energyimage,
      title: "ENERGY",
      type: traitNames[_testResult?.prediction?.mbti?.entity?.code[0]],
      typeValue: 52,
      typecatagory: energy,
      description: "Extraversion and Introversion represent two distinct ways of engaging with the world: one through outward exploration and interaction, and the other through inward reflection and introspection.",
    },
    {
      image: resultImages[1] ? resultImages[1] : mindimage,
      title: "MIND",
      type: traitNames[_testResult?.prediction?.mbti?.entity?.code[1]],
      typeValue: 52,
      typecatagory: mind,
      description: "These two preferences represent different ways of perceiving and processing information, with Sensing focusing on the here and now, while Intuition looks to the future and explores broader patterns and possibilities.",
    },
    {
      title: "FEELING",
      image: resultImages[2] ? resultImages[2] : feelingsimage,
      type: traitNames[_testResult?.prediction?.mbti?.entity?.code[2]],
      typeValue: 52,
      typecatagory: feelings,
      description: "Individuals who prefer Feeling prioritize personal values and empathy in decision-making, while those who prefer Thinking prioritize logic and rationality. Both traits contribute to diverse perspectives and approaches to problem-solving in different situations.",
    }, {
      image: resultImages[3] ? resultImages[3] : judgingimage,
      title: "JUDGING",
      type: traitNames[_testResult?.prediction?.mbti?.entity?.code[3]],
      typeValue: 52,
      typecatagory: judging,
      description: "These traits represent different ways in which individuals approach and interact with the external world, influencing their decision-making processes, planning styles, and overall lifestyle preferences.",
    },

    ])

  }, [_testResult]);

  console.log(resultdata);

  const nextButton = () => {
    if (count < resultdata?.length - 1)
      setCount(count + 1);
    else
      navigate('/detail_result_page');
  };

  const backButton = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  console.log(count);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const length = resultdata?.length;

  return (
    <>
      <Layout>
        {
          loader ?
            <Loader setIsLoading={setLoader} />
            :
            <React.Suspense fallback={<LoadingPage />}>
              <div className="energy-main">
                <div className="energy-main-box">
                  <div className="energy-image">
                    <img src={resultdata[count]?.image} alt="" />
                  </div>
                  <div className="energy-content">
                    <div className="above-section">
                      <div className="above-section-child">
                        {count === 0 ? (
                          <>
                            <h3 className="second-heading">your personality type is</h3>
                            <h4>{_testResult.name}</h4>
                          </>
                        ) : (
                          <>
                            <h1>{resultdata[count]?.title}</h1>
                            <h2>{resultdata[count]?.type}</h2>

                            <div className="percentege-box">
                              <div className="percentage-boxenergy-1" style={{ width: `${resultdata[count]?.typecatagory[0].value}%` }}></div>
                              <div className="percentage-boxenergy-2" style={{ width: `${resultdata[count]?.typecatagory[1].value}%` }}></div>
                            </div>


                            <div className="percentage-numaric">
                              <div className="numaric-first">
                                <p>{resultdata[count]?.typecatagory[0].value}%</p>
                                <p>{resultdata[count]?.typecatagory[0].name}</p>
                              </div>
                              <div className="numaric-second">
                                <p>{resultdata[count]?.typecatagory[1].value}%</p>
                                <p>{resultdata[count]?.typecatagory[1].name}</p>
                              </div>
                            </div>

                          </>
                        )}
                      </div>
                      <p className="" style={{ fontSize: "1.3rem", marginTop: 'auto', marginBottom: 'auto' }}>
                        {resultdata[count]?.description}
                      </p>
                    </div>
                    <div className="belowsection">
                      <div className="below-back-section">
                        {count !== 0 && (
                          <button onClick={backButton} className="first-back">
                            <FaArrowLeft />
                            Back
                          </button>
                        )}
                        {(count !== length || count === 0) && (
                          <button onClick={nextButton} className={`first-next ${count === 0 ? 'centered-button' : ''}`}>
                            Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Suspense>
        }
      </Layout>
      {/* {resultPage && <CareerPAth />} */}
    </>
  );
};

export default ResultPage;
