import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Data1 } from "../../utils/detailResult";
import SideBar from "./SideBar";
import TabSection from "./TabSection";
import BottomSection from "./BottomSection";
import MobProgressBar from "./MobProgressBar";
import { resultSelector, selectedLang, testResult } from "../../utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import config from "../../config.json";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { languagesCode } from "../../utils/data";

const MyProfile = () => {
  const result = useRecoilValue(resultSelector);
  const [loading, setLoading] = useState(true);
  const tokenCookie = Cookies.get("token", { domain: config.cookieDomain });
  const isPaidCookie = Cookies.get("role", { domain: config.cookieDomain });
  const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
  const isTestTaken = Cookies.get("isTestAttended", {
    domain: config.cookieDomain,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (tokenCookie) {
      if (isPaidCookie && isPaidCookie === "ROLE_UNPAID_USER")
        navigate("/home");
      if (isTestTaken && isTestTaken === "FALSE") navigate("/home");
      // if (result?.lang) {
      //   let resultLang = languagesCode.find(lang => lang.code === result?.lang);
      //   setLang(resultLang);
      // }
    } else {
      window.location.href = config.domain + "/login";
    }
  }, []);
  console.log(result);
  return (
    <div>
      <Layout>
        <React.Suspense fallback={<LoadingPage />}>
          {
            result && Object.keys(result).length &&
            [result]?.map((item, index) => (
              <div
                key={index}
                className="px-[4rem] py-[3rem] md-max:px-[2rem] md-max:[2rem] sm-max:px-5 sm-max:py-3"
              >
                <p className="text-xl font-semibold mb-6 sm-max:hidden">
                  Your Results
                </p>
                <div className=" flex gap-5 sm-max:flex-col md-max:flex-col sm-max:gap-0">
                  <div className="w-[30%] md-max:w-full sm-max:w-full">
                    <SideBar item={item} />
                  </div>
                  <div className="w-[70%] flex flex-col gap-5 sm-max:w-full md-max:w-full sm-max:gap-0">
                    <TabSection item={item} />
                    <MobProgressBar item={item} />
                    {/* <BottomSection item={item} /> */}
                  </div>
                </div>
              </div>
            ))
          }
        </React.Suspense>
      </Layout>
    </div>
  );
};

export default MyProfile;
